import React from 'react';

type Props = {};

const UserCard = (props: Props) => {
  return (
    <div className="md:mt-[30px] mt-[10px] md:mb-[40px] mb-[20px] flex gap-[10px] items-center">
      <img
        src="https://placehold.co/150.png"
        alt="user avatar"
        className="h-[45px] w-[45px] rounded-full"
      />
      <div>
        <h3 className="text-white font-medium md:text-[20px] text-[15px]">Hello, Stark</h3>
        <p className="text-[#C2C2C2] md:text-[14px] text-[12px]">What are you trading today?</p>
      </div>
    </div>
  );
};

export default UserCard;
